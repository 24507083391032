import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  wrt: {},
  error: null,
  isLoading: false,
};

export const getFirms = createAsyncThunk(
  "firms/getFirm",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://api-cd.accel-india.co.in/api/investment-firms",

        {
          headers: {
            "Content-Type": "application/json",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(`Progress: ${percentCompleted}%`);
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const firmsSlice = createSlice({
  name: "firms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFirms.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getFirms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.wrt = action.payload.reduce(
          (acc, curr) => ({
            ...acc,
            [curr._id]: curr,
          }),
          {}
        );
      })
      .addCase(getFirms.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default firmsSlice.reducer;
