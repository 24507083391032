import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import appState from "./appState";

const initialState = {
  data: [],
  wrt: {},
  error: null,
  isLoading: false,
};

export const getIntroductions = createAsyncThunk(
  "introductions/getIntroductions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://api-cd.accel-india.co.in/api/introductions",

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response?.data?.introductions || [];
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const markIntroduction = createAsyncThunk(
  "introductions/markIntroductions",
  async ({ _id }, { getState, rejectWithValue }) => {
    try {
      const { introductions } = getState();
      let updatedData = [...introductions.data];
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://api-cd.accel-india.co.in/api/introductions/mark`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          introductionId: _id,
        }),
      };
      const response = await axios.request(config);
      if (response.data.message) {
        updatedData = updatedData.map((el) =>
          el._id == _id
            ? {
                ...el,
                mark: true,
              }
            : {
                ...el,
              }
        );
        return {
          updatedData,
          _id,
        };
      } else {
        return rejectWithValue("Unexpected response from server");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const emailIntroduction = createAsyncThunk(
  "introductions/emailIntroductions",
  async ({ _id }, { getState, rejectWithValue }) => {
    try {
      const { introductions } = getState();
      let updatedData = [...introductions.data];
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://api-cd.accel-india.co.in/api/introductions/email`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          introductionId: _id,
        }),
      };
      const response = await axios.request(config);
      if (response.data.message) {
        updatedData = updatedData.map((el) =>
          el._id == _id
            ? {
                ...el,
                email: true,
              }
            : {
                ...el,
              }
        );
        return {
          updatedData,
          _id,
        };
      } else {
        return rejectWithValue("Unexpected response from server");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const markAndEmailIntroduction = createAsyncThunk(
  "introductions/markAndEmailIntroduction",
  async ({ _id }, { getState, rejectWithValue }) => {
    try {
      const { introductions } = getState();
      let updatedData = [...introductions.data];

      // Mark the introduction
      const markConfig = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://api-cd.accel-india.co.in/api/introductions/mark`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          introductionId: _id,
        }),
      };

      const markResponse = await axios.request(markConfig);
      if (!markResponse.data.message) {
        return rejectWithValue("Unexpected response from server while marking");
      }

      // Email the introduction
      const emailConfig = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://api-cd.accel-india.co.in/api/introductions/email`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          introductionId: _id,
        }),
      };

      const emailResponse = await axios.request(emailConfig);
      if (!emailResponse.data.message) {
        return rejectWithValue(
          "Unexpected response from server while emailing"
        );
      }

      // Update the data to reflect both mark and email actions
      updatedData = updatedData.map((el) =>
        el._id === _id
          ? {
              ...el,
              mark: true,
              email: true,
            }
          : {
              ...el,
            }
      );

      return {
        updatedData,
        _id,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deletelIntroduction = createAsyncThunk(
  "introductions/deleteIntroductions",
  async ({ _id }, { getState, rejectWithValue }) => {
    try {
      const { introductions } = getState();
      let updatedData = [...introductions.data];
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://api-cd.accel-india.co.in/api/introductions/delete`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          introductionId: _id,
        }),
      };
      const response = await axios.request(config);
      if (response.data.message) {
        updatedData = updatedData.filter((el) => el._id !== _id);
        return updatedData;
      } else {
        return rejectWithValue("Unexpected response from server");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default createSlice({
  name: "introductions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIntroductions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getIntroductions.fulfilled, (state, action) => {
        state.isLoading = false;

        state.data = action.payload;
        state.wrt = action.payload?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr._id]: curr,
          }),
          {}
        );
      })
      .addCase(getIntroductions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(markIntroduction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(markIntroduction.fulfilled, (state, action) => {
        state.isLoading = false;

        state.data = action.payload.updatedData;
        state.wrt = {
          ...state.wrt,
          [action.payload._id]: {
            ...state.wrt[action.payload._id],
            mark: true,
          },
        };
      })
      .addCase(markIntroduction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(emailIntroduction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(emailIntroduction.fulfilled, (state, action) => {
        state.isLoading = false;

        state.data = action.payload.updatedData;
        state.wrt = {
          ...state.wrt,
          [action.payload._id]: {
            ...state.wrt[action.payload._id],
            email: true,
          },
        };
      })
      .addCase(emailIntroduction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(markAndEmailIntroduction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(markAndEmailIntroduction.fulfilled, (state, action) => {
        state.isLoading = false;

        state.data = action.payload.updatedData;
        state.wrt = {
          ...state.wrt,
          [action.payload._id]: {
            ...state.wrt[action.payload._id],
            email: true,
            mark: true,
          },
        };
      })
      .addCase(markAndEmailIntroduction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(deletelIntroduction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deletelIntroduction.fulfilled, (state, action) => {
        state.isLoading = false;

        state.data = action.payload;
      })
      .addCase(deletelIntroduction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
}).reducer;
