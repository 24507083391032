export const filters = {
  "Funding Round": [],
  Sector: [],
  City: [],
  Country: [],
  Designation: [],
};

export const fileToBase64 = ({ target }) => {
  const file = target.files[0];
  if (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        // const base64String = e.target.result.split(",")[1]; // Base64 string
        const base64String = e.target.result; // Base64 string
        resolve({
          name: file.name.split(".").slice(0, -1).join("."),
          base64: base64String,
        });
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  } else {
    return Promise.reject(new Error("No file selected"));
  }
};
