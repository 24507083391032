import axios from "axios";

export const ImageToLink = async (base64, callback) => {
  try {
    const response = await axios
      .post(
        `https://api-cd.accel-india.co.in/api/upload`,
        { base64: base64 },
        {}
      )
      .then((res) => {
        return callback({
          status: true,
          response: res.data,
        });
      });
  } catch (error) {
    return callback({
      status: false,
      response: "or Try Again",
    });
  }
};

export const createfiletolink = (fileData, if_successs, if_reject, loading) => {
  let data = JSON.stringify({
    base64: fileData.base64,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api-cd.accel-india.co.in/api/upload",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      loading(percentCompleted);
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
