import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  otp: "",
  token: null,
  isLoading: false,
  error: null,
  showOtp: false,
  name: "",
  userId: "",
  type: "",
};

// Async action to send OTP
export const sendOTP = createAsyncThunk(
  "auth/sendOTP",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://api-cd.accel-india.co.in/api/auth/send-otp",
        JSON.stringify({ email }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.error) {
        return rejectWithValue(response?.data || { error: "Error" });
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Async action to verify OTP
export const verifyOTP = createAsyncThunk(
  "auth/verifyOTP",
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://api-cd.accel-india.co.in/api/auth/verify-otp",
        JSON.stringify({ email, otp }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.error) {
        return rejectWithValue(response?.data || { error: "Error" });
      }
      return response.data; // Assuming the response contains a token
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const checkToken = createAsyncThunk(
  "auth/checkToken",
  async ({ token }, { rejectWithValue }) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    try {
      const response = await axios.post(
        "https://api-cd.accel-india.co.in/api/auth/validity",
        JSON.stringify({}),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("get token data", response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    setshowOtp(state, action) {
      state.showOtp = action.payload;
    },

    setOtp(state, action) {
      state.otp = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    // setAuthData(state, action) {},
  },
  extraReducers: (builder) => {
    // sendOTP cases
    builder
      .addCase(sendOTP.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendOTP.fulfilled, (state) => {
        state.isLoading = false;
        state.showOtp = true;
        state.otp = "";
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // verifyOTP cases
    builder
      .addCase(verifyOTP.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        localStorage.setItem(
          "appdata",
          JSON.stringify({
            // token: action.payload.token,
            // name: action.payload.name,
            // email: action.payload.email,
            // userId: action.payload.userId,
            // type: action.payload.type,
            token: action?.payload?.token || "",
            name: action?.payload?.name || "",
            email: action?.payload?.email || "",
            userId: action?.payload?.userId || "",
            type: action?.payload?.type || "",
          })
        );
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${action.payload.token}`;
        state.isLoading = false;
        state.token = action.payload.token || "";
        state.name = action?.payload?.name || "";
        state.userId = action?.payload?.userId || "";
        state.type = action?.payload?.type || "";
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(checkToken.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(checkToken.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log(action);

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${action.payload.token}`;
        state.token = action.payload.token;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.userId = action.payload.userId;
        state.type = action.payload.type;
        localStorage.setItem(
          "appdata",
          JSON.stringify({
            token: action?.payload?.token || "",
            name: action?.payload?.name || "",
            email: action?.payload?.email || "",
            userId: action?.payload?.userId || "",
            type: action?.payload?.type || "",
          })
        );
      })
      .addCase(checkToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        // if (error?.response?.data?.error == "User not found") {
        state.token = "";
        state.name = "";
        state.email = "";
        state.userId = "";
        state.type = "";
        localStorage.setItem(
          "appdata",
          JSON.stringify({
            token: "",
            name: "",
            email: "",
            userId: "",
            type: "",
          })
        );
        // }
      });
  },
});

export const { setEmail, setshowOtp, setOtp, setError, setAuthData } =
  authSlice.actions;
export default authSlice.reducer;
