import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  investors_firm_relation: {},
  error: null,
  isLoading: false,
};

export const getInvestors = createAsyncThunk(
  "investors/getInvestors",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://api-cd.accel-india.co.in/api/investors",

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const getInvestorsMxailMerge = createAsyncThunk(
  "investors/getInvestorsMailMerge",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://api-cd.accel-india.co.in/api/investors/mail-merge-list",

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export default createSlice({
  name: "investors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInvestors.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getInvestors.fulfilled, (state, action) => {
        state.isLoading = false;
        let investors_firm_relation = {};
        action.payload?.investors?.forEach((people) => {
          investors_firm_relation[people.firmId] = [
            ...(investors_firm_relation[people.firmId] || []),
            { ...people },
          ];
        });
        state.data = action.payload;
        state.investors_firm_relation = investors_firm_relation;
      })
      .addCase(getInvestors.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getInvestorsMxailMerge.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getInvestorsMxailMerge.fulfilled, (state, action) => {
        state.isLoading = false;
        // let investors_firm_relation = {};
        // action.payload?.investors?.forEach((people) => {
        //   investors_firm_relation[people.firmId] = [
        //     ...(investors_firm_relation[people.firmId] || []),
        //     { ...people },
        //   ];
        // });
        state.mailmerge = action.payload;
        // state.investors_firm_relation = investors_firm_relation;
      })
      .addCase(getInvestorsMxailMerge.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
}).reducer;
