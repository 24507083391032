import React, { useLayoutEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";

import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Mention from "@tiptap/extension-mention";
import suggestion from "./suggestion";

export const Subject_editor = ({ suggestions = ["name"], settxt }) => {
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph.extend({
        addKeyboardShortcuts() {
          return {
            Enter: () => true, // Prevent default behavior for Enter key
          };
        },
      }),
      Text,
      ...(suggestions.length
        ? [
            Mention.configure({
              HTMLAttributes: {
                class: "mention",
              },
              renderHTML: ({ node }) => {
                const { label, id } = node.attrs;
                return [
                  "span",
                  {
                    class: "dynamic-variable-button",
                  },
                  `{${label || id}}`,
                ];
              },
              suggestion: {
                ...suggestion,
                items: ({ query, editor }) => {
                  return editor.options.editorProps.items?.filter((item) => {
                    let t_item = item.toLowerCase();
                    let t_query = query.toLowerCase();

                    return t_item.includes(t_query);
                  });
                },
              },
            }),
          ]
        : []),
    ],
    content: `<p></p>`,
    onUpdate: ({ editor }) => {
      const html = editor.getText();
      settxt(html);
      localStorage.setItem("mailSubject", html);
    },
  });

  useLayoutEffect(() => {
    if (editor && suggestions.length > 0) {
      editor.setOptions({
        editorProps: {
          items:
            suggestions?.filter(
              (el) => !["_id", "Photo", "isLiked", "firmId"].includes(el)
            ) || [],
        },
      });
    }

    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, [suggestions, editor]);

  return (
    <>
      <div className="rte_body">
        <div
          className="rich_text_editor"
          onClick={() => {
            if (!editor.isFocused) {
              editor.commands.focus("end");
            }
          }}
        >
          <EditorContent editor={editor} className="editor_main" />
        </div>
      </div>
    </>
  );
};
