import React, { useEffect, useRef, useState } from "react";
import SlickSlider from "./SlickSlider";
// import { pdfjs } from "pdfjs-dist";

// const PdfViewer = ({ src }) => {
//   useEffect(() => {
//     const loadingTask = pdfjs.getDocument(src);
//     loadingTask.promise.then((pdf) => {
//       // Render the first page
//       pdf.getPage(1).then((page) => {
//         const scale = 1.5;
//         const viewport = page.getViewport({ scale });
//         const canvas = document.getElementById("pdf-canvas");
//         const context = canvas.getContext("2d");
//         canvas.height = viewport.height;
//         canvas.width = viewport.width;

//         const renderContext = {
//           canvasContext: context,
//           viewport: viewport,
//         };
//         page.render(renderContext);
//       });
//     });
//   }, [src]);

//   return <canvas id="pdf-canvas" style={{ border: "none" }} />;
// };
export const PreviewPage = ({
  setcurrently_previewing,
  currently_previewing,
}) => {
  return (
    <div className="preview_images">
      <div className="close" onClick={() => setcurrently_previewing([])}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.8132 9.99999C17.8132 10.2486 17.7145 10.4871 17.5387 10.6629C17.3628 10.8387 17.1244 10.9375 16.8757 10.9375H5.39136L9.41636 14.9617C9.59248 15.1378 9.69143 15.3767 9.69143 15.6258C9.69143 15.8748 9.59248 16.1137 9.41636 16.2898C9.24024 16.466 9.00137 16.5649 8.7523 16.5649C8.50323 16.5649 8.26436 16.466 8.08824 16.2898L2.46324 10.6648C2.37584 10.5777 2.30649 10.4742 2.25917 10.3603C2.21186 10.2463 2.1875 10.1242 2.1875 10.0008C2.1875 9.87738 2.21186 9.75521 2.25917 9.64125C2.30649 9.5273 2.37584 9.42381 2.46324 9.33671L8.08824 3.71171C8.17544 3.6245 8.27897 3.55533 8.39291 3.50813C8.50685 3.46094 8.62897 3.43665 8.7523 3.43665C8.87563 3.43665 8.99775 3.46094 9.11169 3.50813C9.22563 3.55533 9.32916 3.6245 9.41636 3.71171C9.50357 3.79891 9.57275 3.90244 9.61994 4.01638C9.66714 4.13032 9.69143 4.25244 9.69143 4.37577C9.69143 4.4991 9.66714 4.62122 9.61994 4.73516C9.57275 4.8491 9.50357 4.95263 9.41636 5.03983L5.39136 9.06249H16.8757C17.1244 9.06249 17.3628 9.16126 17.5387 9.33708C17.7145 9.51289 17.8132 9.75135 17.8132 9.99999Z"
            fill="#000"
          ></path>
        </svg>
      </div>
      {/* {currently_previewing.length > 1 ? (
      <SlickSlider
        list={currently_previewing?.map((el) => {
          return (
            <div className="slider_main">
              <img src={el} />
            </div>
          );
        })}
      />
    ) : (
      <div className="slider_main">
        <img src={currently_previewing[0]} />
      </div>
    )} */}
      {currently_previewing.length > 1 ? (
        <SlickSlider
          list={currently_previewing?.map((el) => {
            return (
              <div className="slider_main">
                {el?.endsWith(".pdf") ? (
                  <div className="pdf_preview">
                    {/* Option 1: PDF viewer */}
                    {/* <Document file={el}>
                      <Page pageNumber={1} />
                    </Document> */}
                    <iframe
                      src={el}
                      style={{
                        width: "800px",
                        height: "500px",
                        border: "none",
                      }}
                      title="PDF Preview"
                    />
                    {/* <PDFViewer url={el} /> */}
                  </div>
                ) : (
                  <img src={el} alt={el} />
                )}
              </div>
            );
          })}
        />
      ) : (
        <div className="slider_main">
          {currently_previewing[0]?.endsWith(".pdf") ? (
            <div className="pdf_preview">
              {/* <Document file={currently_previewing[0]}>
                <Page pageNumber={1} />
              </Document> */}
              <iframe
                src={currently_previewing[0]}
                style={{ width: "800px", height: "500px", border: "none" }}
                title="PDF Preview"
              />
              {/* <PDFViewer url={currently_previewing[0]} /> */}
            </div>
          ) : (
            <img src={currently_previewing[0]} />
          )}
        </div>
      )}
    </div>
  );
};
