import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

export const getInvestors = createAsyncThunk(
  "investors/getInvestors",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://api-cd.accel-india.co.in/api/investors",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NmQ1YTM0NGI3NzE4MjFlM2Q4ZjYzN2YiLCJuYW1lIjoiU2hhbnRhbnUgSXllbmdhciIsImVtYWlsIjoic2hhbnRhbnVAbW9kZXJuaXppbmdwcm9jZXNzZXMuY29tIiwiaWF0IjoxNzI1MzUzNTc2LCJleHAiOjE3NTY4ODk1NzZ9.fE3WhH6prwC1BRHbdjPRIQdFCdGsi0s4Tf6UHEcgw2U",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const authSLice = createSlice({
  name: "investors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInvestors.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getInvestors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getInvestors.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default authSLice.reducer;
