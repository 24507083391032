import React, { useEffect, useLayoutEffect, useState } from "react";
import RTE from "../../component/RTE/RTE";
import { useSelector } from "react-redux";
import { RTE_Remasterd } from "../../component/RTE/RTE_Remasterd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { createfiletolink, ImageToLink } from "../../utils/api";
import { fileToBase64 } from "../../utils/data";
import { PreviewPage } from "../../component/SlickSlider/PreviewPage";
import { Subject_editor } from "../../component/RTE/Subject_editor";

const MailMerge = () => {
  const navigate = useNavigate();

  const store = useSelector((state) => state);
  const auth = useSelector((state) => state.auth);

  const [is_loader, setis_loader] = useState(false);
  const [people, setpeople] = useState([]);
  const [email_sent_counter, setemail_sent_counter] = useState(0);
  const [search_people, setsearch_people] = useState("");
  const [preview_search_people, setpreview_search_people] = useState("");
  const [preview_people, setpreview_people] = useState([]);
  const [subject, setsubject] = useState([]);

  const [message_body, setmessage_body] = useState("");
  const [message, setmessage] = useState([]);
  const [suggestions, setsuggestions] = useState([]);
  const investorData = useSelector((state) => state.investors);
  const [preview, setpreview] = useState(false);
  const [AttachFile, setAttachFile] = useState([]);

  const [curr_images_ids, setcurr_images_ids] = useState([
    // {
    //   // id:"new Date().valueOf()"
    // },
  ]);
  const [currently_previewing, setcurrently_previewing] = useState([]);
  const [curr_images_data, setcurr_images_data] = useState([
    // id:{
    // name:"",
    // base64:""
    // link:""
    // }
  ]);
  useEffect(() => {
    // let mailpeoplefromLocal = localStorage.getItem("mailpeople");
    // let subjectlefromLocal = localStorage.getItem("mailsubject");
    // let attachlefromLocal = localStorage.getItem("mailAttach");
    // if (mailpeoplefromLocal) {
    //   setpeople(JSON.parse(mailpeoplefromLocal));
    // }
    // if (subjectlefromLocal) {
    //   setsubject(subjectlefromLocal);
    // }
    // if (attachlefromLocal) {
    //   setcurr_images_data(JSON.parse(attachlefromLocal));
    // }
  }, []);

  useEffect(() => {
    if (people?.length > 0) {
      setpreview_people(people[0]);
      function getCommonNonEmptyKeys(investorArray) {
        if (investorArray.length === 0) return [];

        // Get the initial set of keys from the first investor object
        let commonKeys = Object.keys(investorArray[0]);

        investorArray.forEach((investor) => {
          commonKeys = commonKeys.filter((key) => {
            const value = investor[key];
            // Check if the value is non-empty
            return (
              value !== null &&
              value !== undefined &&
              value !== "" &&
              !(Array.isArray(value) && value.length === 0)
            );
          });
        });

        // Verify that each key in the final set is present in every investor
        return commonKeys.filter((key) =>
          investorArray.every((investor) => key in investor)
        );
      }
      setsuggestions(getCommonNonEmptyKeys(people));
    }
  }, [people]);
  const sendMail = () => {
    if (
      !(people.length > 0 && subject.length > 2 && message_body.length > 10)
    ) {
      return;
    }
    // setis_loader(true);

    let data = {
      arr: people.map((el) => ({
        to: el.Email,
        subject: (() =>
          Object.keys(el).reduce(
            (acc, curr) => acc.replaceAll(`@${curr}`, el[curr]),
            subject
          ))(),
        html: (() =>
          Object.keys(el).reduce(
            (acc, curr) => acc.replaceAll(`{${curr}}`, el[curr]),
            message_body
          ))().replaceAll("<p>", `<p style="min-height: 20px;margin: 0">`),
        attachments: curr_images_data.map(({ name, link }) => ({
          name: name + "." + link.split(".").slice(-1),
          url: link,
        })),
      })),
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api-cd.accel-india.co.in/api/send-email",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
      onUploadProgress: (progressEvent) => {
        // Calculate the percentage of completion
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(`Progress: ${percentCompleted}%`);
        // setis_loader(false);
        if (percentCompleted >= 100) {
          // setis_loader(false);
          // setemail_sent_counter(0);
          // setpeople([]);
          // setsubject("");
          localStorage.setItem("mailData", "<p></p>");
          window.location.reload();
        }
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

    // send mail function here
    // people.map((el, i) => {
    //   let data = JSON.stringify({
    //     to: el.email,
    //     subject: subject,
    //     html: (() =>
    //       Object.keys(el).reduce(
    //         (acc, curr) => acc.replaceAll(`{${curr}}`, el[curr]),
    //         message_body
    //       ))(),
    //   });

    //   let config = {
    //     method: "post",
    //     maxBodyLength: Infinity,
    //     url: "https://api-cd.accel-india.co.in/api/send-email",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     data: data,
    //   };

    //   axios
    //     .request(config)
    //     .then((response) => {
    //       console.log(JSON.stringify(response.data));
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });

    //   // setTimeout(() => {
    //   //   setemail_sent_counter((prev) => {
    //   //     if (people.length == prev + 1) {
    //   //       setis_loader(false);
    //   //       setemail_sent_counter(0);
    //   //       setpeople([]);
    //   //       setsubject("");
    //   //       localStorage.setItem("mailData", "<p></p>");
    //   //     }
    //   //     return prev + 1;
    //   //   });
    //   // }, 1000 * i);
    // });
  };

  console.log(curr_images_data);

  // if (!is_loader) {
  //   return <div class="loading">loading</div>;
  // }

  return (
    <div className="mail_merge">
      {is_loader ? (
        <div className="loading">
          <div className="txt1">Sending email please wait</div>
        </div>
      ) : (
        <>
          {currently_previewing.length > 0 ? (
            <PreviewPage
              {...{
                setcurrently_previewing,
                currently_previewing,
              }}
            />
          ) : (
            // <div className="preview_images">
            //   <div
            //     className="close"
            //     onClick={() => setcurrently_previewing([])}
            //   >
            //     <svg
            //       width="20"
            //       height="20"
            //       viewBox="0 0 20 20"
            //       fill="none"
            //       xmlns="http://www.w3.org/2000/svg"
            //     >
            //       <path
            //         d="M17.8132 9.99999C17.8132 10.2486 17.7145 10.4871 17.5387 10.6629C17.3628 10.8387 17.1244 10.9375 16.8757 10.9375H5.39136L9.41636 14.9617C9.59248 15.1378 9.69143 15.3767 9.69143 15.6258C9.69143 15.8748 9.59248 16.1137 9.41636 16.2898C9.24024 16.466 9.00137 16.5649 8.7523 16.5649C8.50323 16.5649 8.26436 16.466 8.08824 16.2898L2.46324 10.6648C2.37584 10.5777 2.30649 10.4742 2.25917 10.3603C2.21186 10.2463 2.1875 10.1242 2.1875 10.0008C2.1875 9.87738 2.21186 9.75521 2.25917 9.64125C2.30649 9.5273 2.37584 9.42381 2.46324 9.33671L8.08824 3.71171C8.17544 3.6245 8.27897 3.55533 8.39291 3.50813C8.50685 3.46094 8.62897 3.43665 8.7523 3.43665C8.87563 3.43665 8.99775 3.46094 9.11169 3.50813C9.22563 3.55533 9.32916 3.6245 9.41636 3.71171C9.50357 3.79891 9.57275 3.90244 9.61994 4.01638C9.66714 4.13032 9.69143 4.25244 9.69143 4.37577C9.69143 4.4991 9.66714 4.62122 9.61994 4.73516C9.57275 4.8491 9.50357 4.95263 9.41636 5.03983L5.39136 9.06249H16.8757C17.1244 9.06249 17.3628 9.16126 17.5387 9.33708C17.7145 9.51289 17.8132 9.75135 17.8132 9.99999Z"
            //         fill="#000"
            //       ></path>
            //     </svg>
            //   </div>
            //   {/* {currently_previewing.length > 1 ? (
            //     <SlickSlider
            //       list={currently_previewing?.map((el) => {
            //         return (
            //           <div className="slider_main">
            //             <img src={el} />
            //           </div>
            //         );
            //       })}
            //     />
            //   ) : (
            //     <div className="slider_main">
            //       <img src={currently_previewing[0]} />
            //     </div>
            //   )} */}
            //   {currently_previewing.length > 1 ? (
            //     <SlickSlider
            //       list={currently_previewing?.map((el) => {
            //         return (
            //           <div className="slider_main">
            //             {el?.endsWith(".pdf") ? (
            //               <div className="pdf_preview">
            //                 {/* Option 1: PDF viewer */}
            //                 <Document file={el}>
            //                   <Page pageNumber={1} />
            //                 </Document>
            //               </div>
            //             ) : (
            //               <img src={el} alt={el} />
            //             )}
            //           </div>
            //         );
            //       })}
            //     />
            //   ) : (
            //     <div className="slider_main">
            //       {currently_previewing[0]?.endsWith(".pdf") ? (
            //         <div className="pdf_preview">
            //           <Document file={currently_previewing[0]}>
            //             <Page pageNumber={1} />
            //           </Document>
            //         </div>
            //       ) : (
            //         <img src={currently_previewing[0]} />
            //       )}
            //     </div>
            //   )}
            // </div>
            ""
          )}
          <label className="send_to" htmlFor="people_input">
            <div className="send_to_txt">To:</div>
            <div className="send_to_main">
              {people?.map((el) => (
                <div
                  className={
                    "people_item" +
                    (preview_people.email_sent_counter == el.email_sent_counter
                      ? " preview_selected"
                      : "")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setpreview_people({ ...el });
                  }}
                >
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${el["Photo"]})`,
                    }}
                  ></div>
                  <div className="people_details">
                    <div className="email">{el["Email"]}</div>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setsearch_people("");
                      setpeople((prev) => {
                        let data = prev.filter(
                          (curr) => curr["Email"] != el["Email"]
                        );
                        localStorage.setItem(
                          "mailpeople",
                          JSON.stringify(data)
                        );
                        return data;
                      });
                    }}
                    className="remove_btn"
                  >
                    <svg
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      viewBox="0 0 320.591 320.591"
                    >
                      <g>
                        <path
                          d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                          fill="#000000"
                          opacity="1"
                          className="hovered-path"
                        ></path>
                        <path
                          d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                          fill="#000000"
                          opacity="1"
                          className="hovered-path"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
              ))}
              {!investorData.isLoading ? (
                <label className="people_dropdown" htmlFor="people_selector">
                  <div
                    // onChange={(e) => {
                    //   setpeople(e.target.value);
                    // }}
                    className="add"
                  >
                    <input
                      type="text"
                      id="people_input"
                      value={search_people}
                      onChange={({ target: { value } }) => {
                        setsearch_people(value);
                      }}
                      autoComplete={false}
                    />

                    {/* <input type="radio" id="people_selector" /> */}
                    <div className="people_dropdown_body">
                      <div>
                        {investorData?.mailmerge
                          ?.filter((el) =>
                            [el["Email"], el["Name"]]
                              ?.join(",")
                              .includes(search_people)
                          )
                          .map((el) => {
                            let is_exists =
                              people.filter(
                                (curr) => curr["Email"] == el["Email"]
                              ).length > 0;
                            return (
                              <div
                                onClick={() => {
                                  setsearch_people("");
                                  if (is_exists) {
                                    setpeople((prev) => {
                                      let data = prev.filter(
                                        (curr) => curr["Email"] != el["Email"]
                                      );
                                      localStorage.setItem(
                                        "mailpeople",
                                        JSON.stringify(data)
                                      );
                                      return data;
                                    });
                                  } else {
                                    setpeople((prev) => {
                                      let data = [...prev, el];
                                      localStorage.setItem(
                                        "mailpeople",
                                        JSON.stringify(data)
                                      );
                                      return data;
                                    });
                                  }
                                }}
                                className={
                                  is_exists
                                    ? "people_item selected"
                                    : "people_item"
                                }
                              >
                                <div
                                  className="img"
                                  style={{
                                    backgroundImage: `url("${el["Photo"]}")`,
                                  }}
                                ></div>
                                <div className="people_details">
                                  <div className="name">{el["Name"]}</div>
                                  <div className="email">{el["Email"]}</div>
                                </div>
                              </div>
                            );
                          })}
                        {auth.type == "Admin" ? (
                          <div
                            className={"people_item add_person"}
                            onClick={() => {
                              // localStorage.setItem(
                              //   "addInvestor",
                              //   JSON.stringify({
                              //     email: search_people,
                              //   })
                              // );
                              navigate(`../admin/investors?${search_people}`);
                            }}
                          >
                            Create <b>{search_people}</b>
                          </div>
                        ) : (
                          <div className={"people_item add_person"}>
                            No data found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </label>
              ) : (
                "Fetching Investors..."
              )}
            </div>
            <div
              className="send_btn"
              active={String(
                people.length > 0 &&
                  subject.length > 2 &&
                  message_body.length > 10
              )}
              onClick={sendMail}
            >
              <svg
                width="20"
                height="20"
                x="0"
                y="0"
                viewBox="0 0 176.368 176.368"
              >
                <g>
                  <path
                    d="M175.865 12.489c2.469-7.408-4.578-14.456-11.986-11.987L6.48 52.969c-8.839 2.946-8.565 15.542.393 18.101l76.552 21.873 21.872 76.552c2.56 8.959 15.155 9.233 18.101.393zm-12.34 7.055-49.116 147.348-21.83-76.403zm-6.701-6.701L85.879 83.788 9.477 61.959z"
                    clipRule="evenodd"
                    fill="#000000"
                    opacity="1"
                  ></path>
                </g>
              </svg>
            </div>
          </label>
          <label className="subject" htmlFor="subject_input">
            <div className="subject_txt">Subject:</div>
            {/* <input
              rows={1}
              type="text"
              id="subject_input"
              value={subject}
              onChange={(e) => {
                setsubject(e.target.value);
                localStorage.setItem("mailsubject", e.target.value);
              }}
            /> */}
            <Subject_editor
              {...{
                preview_people,
                settxt: (txt) => setsubject(txt),
                formatting: false,
                suggestions: suggestions,
                setcurr_images_ids,
                setcurr_images_data,
                curr_images_data,
                preview,
                setpreview,
                preview_people,
                message_body,
              }}
            />
          </label>

          <RTE_Remasterd
            {...{
              preview_people,
              settxt: (txt) => setmessage_body(txt),
              formatting: true,
              suggestions: suggestions,
              setcurr_images_ids,
              setcurr_images_data,
              curr_images_data,
              preview,
              setpreview,
              preview_people,
              message_body,
            }}
          />

          {/* {people.length > 0 &&
            subject.length > 2 &&
            message_body.length > 10 && (
              <div className="send_btn" onClick={sendMail}>
                Send
              </div>
            )} */}
        </>
      )}
    </div>
  );
};

export default MailMerge;

//
