import React from "react";

import Slider from "react-slick";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
};
const SlickSlider = ({ list }) => {

  return (
    <Slider {...settings} style={{ margin: "0 -10px" }}>
      {list.map((el) => (
        <div>
          <div style={{ padding: "0 10px" }}>{el}</div>
        </div>
      ))}
    </Slider>
  );
};

export default SlickSlider;
